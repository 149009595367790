<template>
  <v-dialog v-model="dialog_attachment" persistent width="1000" scrollable>
    <v-card>
      <v-card-title>
        <span>Sisipkan Gambar {{ state }}</span>
        <v-spacer></v-spacer>
        <v-icon color="red" size="30" @click="close">mdi-close</v-icon>
      </v-card-title>
      <v-col class="px-6 py-0">
        <v-tabs color="#F05326" v-model="tab" background-color="transparent">
          <v-tab class="text-capitalize"> Riwayat </v-tab>
          <v-tab class="text-capitalize"> URL gambar </v-tab>
          <v-tab class="text-capitalize"> Dari komputer </v-tab>
        </v-tabs>
      </v-col>
      <v-divider></v-divider>
      <v-card-text class="d-md-flex pa-0" style="height: 510px">
        <v-col class="fill-height background pa-0 overflow-auto">
          <v-tabs-items class="transparent fill-height" v-model="tab">
            <v-tab-item>
              <v-card-text style="height: 470px; overflow: auto" class="pb-5">
                <v-text-field
                  solo
                  filled
                  dense
                  flat
                  :hide-details="true"
                  placeholder="Search your image by title . . ."
                  autocomplete="off"
                  v-model="searching"
                  color="#F05326"
                  v-on:keyup.enter="fetchRecents"
                  prepend-inner-icon="mdi-magnify"
                >
                  <template v-if="searching" v-slot:append>
                    <v-icon
                      color="#F05326"
                      @click="
                        searching = '';
                        fetchRecents();
                      "
                      >mdi-close</v-icon
                    >
                  </template>
                </v-text-field>
                <v-list nav color="background" flat class="mt-3">
                  <v-list-item-group
                    mandatory
                    active-class="bordered-1"
                    v-model="selected"
                  >
                    <v-row>
                      <template>
                        <v-col
                          v-for="recent in recents"
                          :key="recent.id"
                          cols="4"
                          :style="
                            recent == recents[selected]
                              ? 'opacity:1;border: 2px solid #F05326'
                              : 'opacity:.3'
                          "
                          class="rounded-lg"
                        >
                          <v-skeleton-loader
                            type="image"
                            v-if="$store.state.process.run"
                          ></v-skeleton-loader>
                          <v-list-item
                            v-show="!$store.state.process.run"
                            class="pa-0"
                          >
                            <v-img
                              :src="recent.public_url"
                              :alt="recent.alt"
                              contain
                              aspect-ratio="1"
                            >
                              <template slot="placeholder">
                                <v-row
                                  class="fill-height"
                                  no-gutters
                                  align="center"
                                  justify="center"
                                >
                                  <v-progress-circular
                                    indeterminate
                                    color="#F05326"
                                  >
                                  </v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                          </v-list-item>
                          <v-list-item-subtitle class="text-wrap text-center">
                            {{ recent.title }}
                          </v-list-item-subtitle>
                        </v-col>
                      </template>
                      <template v-if="recents.length < 1">
                        <v-col> No recent media </v-col>
                      </template>
                    </v-row>
                  </v-list-item-group>
                  <div class="text-center mt-4">
                    <div class="mb-2">
                      Showing {{ recents.length }} from {{ recent.total }}
                    </div>
                    <v-btn
                      color="primary"
                      small
                      elevation="0"
                      @click="fetchRecents"
                    >
                      More
                    </v-btn>
                  </div>
                </v-list>
              </v-card-text>
            </v-tab-item>
            <v-tab-item>
              <v-card-text class="px-8 py-6">
                <v-row>
                  <v-col>
                    <div class="subtitle-2 mb-2">Masukkan URL</div>
                    <v-text-field
                      outlined
                      dense
                      color="#F05326"
                      hide-details="auto"
                      autocomplete="off"
                      v-model="url"
                      @change="setFile"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row v-if="$store.state.process.run">
                  <v-col>
                    <v-skeleton-loader type="image"></v-skeleton-loader>
                  </v-col>
                </v-row>
                <v-row
                  v-else-if="!_.isEmpty(file[0]) && file[0].url != undefined"
                >
                  <v-col>
                    <v-img class="rounded" :src="file[0].url"></v-img>
                  </v-col>
                </v-row>
                <v-row v-else-if="errors">
                  <v-col>
                    <div class="text-center title color--text text--lighten-3">
                      {{ errors.message }}
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>
            <v-tab-item
              class="fill-height"
              style="position: relative"
              @dragover.prevent="draging = true"
              @dragleave="draging = false"
              @drop.prevent="setFile"
            >
              <v-card-text class="fill-height d-flex">
                <v-overlay
                  absolute
                  :value="true"
                  :z-index="draging ? 5 : -1"
                  :opacity="draging ? 0.46 : 0"
                />

                <input
                  hidden
                  ref="file"
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg,image/jpg"
                  @change="setFile"
                />
                <v-col v-if="_.isEmpty(file[0])" class="text-center my-auto">
                  <div class="mb-8">
                    <v-btn
                      color="#F05326"
                      elevation="0"
                      outlined
                      @click="$refs.file.click()"
                    >
                      Pilih berkas
                    </v-btn>
                  </div>
                  <div v-if="!draging" class="subtitle-1 mb-4">
                    Atau tarik file ke sini
                  </div>
                  <div v-else class="subtitle-1 mb-r">Lepas file di sini</div>
                </v-col>

                <v-col v-else>
                  <v-img
                    contain
                    aspect-ratio="2"
                    class="rounded"
                    :src="file[0].url"
                  >
                    <v-col class="text-right">
                      <v-btn
                        color="white"
                        x-small
                        fab
                        elevation="0"
                        @click="$refs.file.click()"
                      >
                        <v-icon>mdi-undo-variant</v-icon>
                      </v-btn>
                    </v-col>

                    <template slot="placeholder">
                      <v-row
                        class="fill-height"
                        no-gutters
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular indeterminate color="#F05326">
                        </v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
        </v-col>

        <!-- FORNEXT: make expand transition -->
        <div
          class="col-md-4 col-12 pa-0"
          v-if="file[0] && (file[0].url || file[0].public_url)"
        >
          <v-col class="white pa-0 fill-height-md overflow-auto">
            <div class="d-md-flex">
              <v-divider class="hidden-md-and-up"></v-divider>
              <v-divider class="hidden-sm-only" vertical></v-divider>
              <div class="pa-6">
                <div class="title mb-2">Information media</div>
                <v-text-field
                  class="py-3"
                  :readonly="isReadOnly"
                  outlined
                  dense
                  color="#F05326"
                  label="Title"
                  hide-details="auto"
                  autocomplete="off"
                  v-model="file[0].title"
                >
                </v-text-field>
                <v-text-field
                  class="py-3"
                  :readonly="isReadOnly"
                  dense
                  outlined
                  color="#F05326"
                  label="Caption"
                  hide-details="auto"
                  autocomplete="off"
                  v-model="file[0].caption"
                >
                </v-text-field>
                <v-text-field
                  class="py-3"
                  :readonly="isReadOnly"
                  dense
                  outlined
                  color="#F05326"
                  label="Alt"
                  hide-details="auto"
                  autocomplete="off"
                  v-model="file[0].alt"
                >
                </v-text-field>
                <v-textarea
                  class="py-3"
                  :readonly="isReadOnly"
                  outlined
                  dense
                  hide-details="auto"
                  label="Description"
                  rows="3"
                  no-resize
                  color="#F05326"
                  v-model="file[0].description"
                >
                </v-textarea>
                <v-scroll-y-transition>
                  <v-alert
                    dense
                    outlined
                    text
                    dismissible
                    icon="mdi-alert-circle-outline"
                    color="#e74c3c"
                    class="mb-1 mt-5"
                    v-show="error.message.length > 0">
                    <div class="subtitle-2">{{ error.message }}</div>
                  </v-alert>
                </v-scroll-y-transition>
                <v-row>
                  <v-col>
                    <v-btn
                      color="#F05326"
                      elevation="0"
                      class="white--text"
                      @click="setAttachment(file[0])"
                      :loading="$store.state.process.run"
                    >
                      Lanjut
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { upload, get_media } from '@/service/Axios'
export default {
  props: {
    dialog_attachment: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: 'logo',
    },
    company_id: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      searching: "",
      dialog:{
        attachment: false
      },
      tab: 2,
      file: [],
      selected: null,
      url: "",
      filepreview: {
        media_data: {}
      },
      recents: [],
      recent: {},
      errors: {},
      draging: false,
      /**
       * Attachment
       */
      dragging: false,
      // file: '',
      filepreview: '',
      fileUpload: [],
      error: {
        image_url: "",
        message: ""
      },
      form: {
        image_url: "",
        title: "",
      },
    }
  },
  watch: {
    tab() {
      if (this.tab == 0 || this.recent.length > 0) this.setFile(this.selected);
      if(this.tab !== 0) this.setFile(this.selected);
    },
    selected() {
      if (this.tab == 0 || this.recent.length > 0) this.setFile(this.selected)
    },
  },
  computed:{
    isReadOnly() {
      return this.tab == 0 || this.$store.state.process.run || this.$store.state.process.run;
    }
  },
  mounted(){
    this.fetchRecents()
  },
  methods: {
    /*
    HANDLE UPLOAD FILE
    */
    async fetchRecents() {
			this.$store.state.process.run = true
			let limit = 9 + this.recent.limit || 9;
			await get_media(`storage/v1/superuser/list`,{
        params: {
          title: this.searching,
          company_id: this.company_id,
          limit,
          type: "image", 
          sort: 'created_at', 
          dir: 'desc' 
        },
      }).then((response) => {
				let res = response.data
				if (res.status == 200) {
					this.$store.state.process.run = false
					this.recent = res.data;
					this.recents = res.data.list;
				}
			});
      this.show = true;
    },
    setFile($event) {
      let vm = this;
      let src = {
        url: "",
        title: "",
        alt: "",
        description: "",
        caption: "",
      };

      switch (vm.tab) {
        // From recent
        case 0:
          Object.assign(src, this.recents[$event]);
          vm.$set(vm.file, 0, src);
          this.$emit("input", vm.file)
          // console.log(this.file);
          break;
        // From url
        case 1:
          var img = new Image();
          if (!$event) {
            vm.errors = {};
            vm.$set(vm.file,0, {});
            return;
          }

          this.loading = true;
          img.src = $event;
          img
            .decode()
            .then(() => {
              vm.errors = {};
              // src.url = img.src;
              src.url = img.src;
              src.title = img.src.split("/").pop();
              src.alt = img.alt;
              src.type = "image";
              src.source = "url";
              vm.$set(vm.file,0, src);
              this.loading = false;
            })
            .catch((error) => {
              vm.errors = error;
              vm.$set(vm.file, 0, {});
              this.$emit("input", vm.file)
              this.loading = false;
            });
          break;
        // From file
        case 2:

          if (this.draging) {
            this.draging = false;
            src.url = URL.createObjectURL($event.dataTransfer.files[0]);
            src.title = $event.dataTransfer.files[0].name;
            src.alt = $event.dataTransfer.files[0].name;
            src.file = $event.dataTransfer.files[0];
          } else {
            src.url = URL.createObjectURL($event.target.files[0]);
            src.title = $event.target.files[0].name;
            src.alt = $event.target.files[0].name;
            src.file = $event.target.files[0];
          }
          src.source = "upload";
          vm.$set(vm.file, 0, src);
          this.$emit("input", vm.file)
          break;
        default:
          break;
      }
    },
    setAttachment(file) {
      // FIXME: Masih bisa upload berbagai macam file
      if (this.tab) {
        this.saving = true;
				this.$store.state.process.run = true
        let formData = new FormData()
        formData.append('source',file.source)
        formData.append('alt',file.alt)
        formData.append('caption', file.caption)
        formData.append('description', file.description)
        formData.append('file', file.file)
        formData.append('title',`${file.title}`)
        formData.append('url', file.url)

        upload(`storage/v1/superuser/upload?company_id=${this.company_id}`, {
          data: formData
        }).then((response) => {
					let res = response.data
					if (res.status == 200) {
            file = { with_media: true, _id: res.data.id, media: res.data, status: this.state };
            this.saving = false;
						this.$store.state.process.run = false
            // console.log(file);
            this.form.image_url = file.media.public_url
            // console.log(this.form.image_url);
            this.filepreview = file
            this.$emit("file_response", this.filepreview)
            this.close();
					}
				}).catch((error) => {
            this.errors.message = error.message;
            this.saving = false;
						this.$store.state.process.run = false
				});
      } else {
        file = { with_media: !this._.isEmpty(file), _id: file.id, media: file, status: this.state };
        this.form.image_url = file.media.public_url
        // console.log(this.form.image_url);
        this.filepreview = file
        this.$emit("file_response", this.filepreview)
        this.close();
      }
    },
    close() {
      this.$delete(this.file, 1);
      this.$delete(this.file, 2);
      this.show = false;
      this.recents = [];
      this.recent = {};
      this.selected = null;
      this.url = "";
      this.file = []
      this.dialog_attachment = false
      this.state = ''
      this.$emit("close")
    },
    removeFile(){
      this.show = false;
      this.recents = [];
      this.file = []
      this.recent = {};
      this.selected = null;
      this.url = "";
    },
    onChange(e) {
      let src = {
        src: null,
        title: null,
        alt: null,
        description: null,
        caption: null,
      };
      var files = e.target.files || e.dataTransfer.files;
      let selectedFiles = e.target.files[0] || e.dataTransfer.files[0]

      if (this.draging) {
        this.draging = false;
        this.filepreview = URL.createObjectURL(selectedFiles);
        src.src = URL.createObjectURL(selectedFiles);
        src.title = selectedFiles.name;
        src.alt = selectedFiles.name;
      } else {
        this.filepreview = URL.createObjectURL(e.target.files[0]);
        src.src = URL.createObjectURL(e.target.files[0]);
        src.title = e.target.files[0].name;
        src.alt = e.target.files[0].name;
      }
      
      if (!files.length) {
        this.dragging = false;
        return;
      }

      this.fileUpload = files
    },
    /*
    HANDLE UPLOAD FILE END
    */
  }
}
</script>